@font-face {
  font-display: swap;
  font-family: "AachenBT-Roman";
  src: local("AachenBT-Roman"),
    url(./assets/fonts/AachenBT-Roman.ttf) format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "AvenirNext-Regular";
  src: local("AvenirNext-Regular"),
    url(./assets/fonts/AvenirNext-Regular.ttf) format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "AvenirNext-Medium";
  src: local("AvenirNext-Medium"),
    url(./assets/fonts/AvenirNext-Medium.ttf) format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "AvenirNext-Bold";
  src: local("AvenirNext-Bold"),
    url(./assets/fonts/AvenirNext-Bold.ttf) format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Helvetica-Bold";
  src: local("Helvetica-Bold"),
  url(./assets/fonts/Helvetica-Bold.ttf) format("truetype");
}

html {
  overflow: hidden;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-form-item-explain {
  font-size: smaller;
}

.ant-form-item .ant-form-item-label {
  padding: 0;
}

.screen-container {
  display: flex;
  flex-direction: column;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.screen-body {
  flex: 1;
  padding: 30px 24px;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: -8px;
  z-index: 2;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.screen-body::-webkit-scrollbar {
  display: none;
}

.screen-vertical-space {
  height: 100px;
}

[class^="css-text-"] {
  font-family: @FONT-AVENIR-REGULAR;
  font-size: 14px;
  color: @COLOR-WARM_GREY;
}

[class^="css-"] {
  font-family: @FONT-AVENIR-REGULAR;
  font-size: 14px;
  color: @COLOR-WARM_GREY;
}

[class^="css-view-"] {
  font-family: @FONT-AVENIR-REGULAR;
  font-size: 12px;
  color: rgb(127, 140, 141);
}

.MuiFormLabel-root {
  font-family: @FONT-AVENIR-MEDIUM !important;
  font-size: 14px !important;
}

.separator {
  height: 1px;
  background-color: @COLOR-DARK-CYAN;
  opacity: 0.2;
  margin-left: 16px;
  margin-right: 16px;
}

.shortSeparator {
  height: 1px;
  background-color: @COLOR-DARK-CYAN;
  opacity: 0.2;
  margin-left: 10%;
}

iframe {
  border: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@media only screen and (min-width: 768px) {
  .screen-container {
    background-color: @COLOR-HEADER;
    align-items: center;
  }
  .screen-body {
    position: relative;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

@COLOR-HEADER: black;@COLOR-HIGHLIGHT: #a2ad00;@COLOR-BUTTON: #282828;@COLOR-BUTTON-TEXT: #D8F52B;@COLOR-CORPORATE-C: #D8F52B;@COLOR-CORPORATE-C-LIGHT: white;@COLOR-OVERLAY-HIGHLIGHT: rgba(0,119,190,0.1);@FONT-BOLD: "AvenirNext-Bold", serif;@FONT-MEDIUM: "AvenirNext-Medium", serif;@FONT-REGULAR: "AvenirNext-Regular", serif;@COLOR-LIGHT-BLACK: rgb(56,56,56);@COLOR-DANGER: #d32f2f;@COLOR-DISABLE: #9E9E9E;@COLOR-GREY: rgba(0,0,0,0.53);@COLOR-CEMENT: rgb(127, 140, 141);@COLOR-DARK-CYAN: #7f8c8d;@COLOR-WARM_GREY: #838383;@COLOR-BROWN_GREY: #7f7f7f;@COLOR-BROWNISH_GREY_TWO: #666666;@COLOR-WHITE-07: rgba(255, 255, 255, 0.7);@COLOR-PERIWINKLE_BLUE: rgb(156, 172, 241);@COLOR-TAR_08: rgba(47, 46, 46, 0.8);@COLOR-DARK_GREEN: #026c45;@FONT-AVENIR-REGULAR: "AvenirNext-Regular", serif;@FONT-AVENIR-MEDIUM: "AvenirNext-Medium", serif;@FONT-AVENIR-BOLD: "AvenirNext-Bold", serif;